<template>
  <vs-button @click="getMemberLocationByArea()" color="primary" type="border" class="mb-4 md:mb-0">Excel ตำแหน่งมาตรน้ำ</vs-button>
</template>

<script>
  import moment from "moment";
  import moduleMember from "@/store/member/moduleMember.js";
  import * as XLSX from "xlsx";

  export default {
    props: ['billConfig', 'area', 'org', 'status'],
    components: {
    },
    name: "P17PrapaReport",
    data() {
      return {
        thmonth: ['', 'มกราคม', 'กุมภาพันธ์', 'มีนาคม',
          'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน',
          'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
      }
    },
    methods: {
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงาน ป.17 ค่าน้ำ',
          acceptText: 'ปิด'
        })
      },

      async getMemberLocationByArea() {
        this.$vs.loading()
        await this.$store.dispatch("member/fetchDataListItemsByArea", {}).then(res => {
          if (res.status === 200) {
            this.$vs.loading.close();
            let response = res.data.filter(function (item) {
              return item.location !== '' && item.location !== undefined
            });
            this.generatePDF(response);
          }
        })
        // this.user = this.$store.state.member.items;
        this.$vs.loading.close();
      },
      generatePDF(response) {
        let array = [];
        const areaList = this.area
        for (let i = 0; i < response.length; i++) {
          const data = response[i];
          let area = areaList.filter(function (item) {
            return item.value === data.M02
          });
          array.push({
            'ลำดับที่': i +1,
            'รหัส': data.M01,
            'รหัสเส้นทาง': data.M02,
            'เส้นทาง': area[0].label,
            'ชื่อสกุลสมาชิกที่ใช้น้ำ' : data.M04,
            'เลขประจำมาตร' : data.M09,
            'บ้านเลขที่': data.M05,
            'ที่อยู่': data.M06,
            'ตำแหน่งมาตร': data.location,
          });
        }
        let fileName = `ตำแหน่งมาตรน้ำ-` + moment().format('DD-MM-YYYY') + '.xlsx';
        let ws = XLSX.utils.json_to_sheet(array);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, fileName);
        XLSX.writeFile(wb, fileName);
      }
    },
    created() {
      if (!moduleMember.isRegistered) {
        this.$store.registerModule('member', moduleMember);
        moduleMember.isRegistered = true;
      }
    }
  }
</script>
